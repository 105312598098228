import {action, computed, observable} from "mobx";
import {ClientInterface} from "../interfaces/ClientInterface";
import {LicenceInterface} from "../interfaces/LicenceInterface";

class ClientMob {
    @observable client = {} as ClientInterface

    constructor(client:ClientInterface | null) {
        this.client = client ? client : {
            currentLicence: null,
            description: null,
            id: -1,
            isConfigured: false,
            uuid: null
        }
    }

    @computed
    get getAllProductsNames(){
        return this.client.currentLicence?.products.map(elem => elem.name)
    }

    @action
    removeUuid = () => this.client.uuid = null

    @action
    updateLicense = (license:LicenceInterface) => this.client.currentLicence = license

    @action
    deleteLicense = () => this.client.currentLicence = null

    @action
    editDescription = (description:string) => this.client.description = description

    @action
    configClient = () => this.client.isConfigured = true
}
export default ClientMob