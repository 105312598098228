export const urls = {
    login:{
        url: 'api/users/user/info',
        method: 'GET'
    },
    getAllClients:{
        url: 'api/clients',
        method: 'GET'
    },
    addClient:{
        url: 'api/clients/client',
        method: 'POST'
    },
    getFile:{
        url:(clientId:number) => `api/clients/client/${clientId}/conf`,
        method:'GET'
    },
    getClient:{
        url:(clientId:number) => `api/clients/client/${clientId}`,
        method:'GET'
    },
    updateDescription:{
        url:(clientId:number, description:string) => `api/clients/client/${clientId}/${description}`,
        method: 'PUT'
    },
    getAllLicences:{
        url: (clientId:number) => `api/clients/client/licenses/${clientId}`,
        method: 'GET'
    },
    getAllProducts:{
        url:'api/products',
        method: 'GET'
    },
    createLicense:{
        url:'api/license',
        method: 'POST'
    },
    updateLicense:{
        url:'api/license',
        method:'PUT'
    },
    deleteLicence:{
        url: (clientId:number) => `api/license/${clientId}`,
        method:'DELETE'
    },
    getAllLaunches:{
        url: (clientId:number) => `api/launches/${clientId}`,
        method: 'GET'
    },
    removeUUID:{
        url:(clientId:number) => `api/clients/client/${clientId}/uuid`,
        method: 'DELETE'
    },
    getStatisticByProduct:{
        url: (productId:number) => `api/launches/product/${productId}`,
        method: 'GET'
    },
    getAllLaunchesProducts:{
        url: 'api/launches',
        method: 'GET'
    }
}