import {action, observable} from "mobx";
import {UserInterface} from "../interfaces/UserInterface";
import {ClientInterface} from "../interfaces/ClientInterface";
import ClientMob from "./ClientMob";

class UserMob {
    @observable user = {} as UserInterface
    @observable.shallow clients = [] as ClientMob[]

    constructor() {
        this.user = {
            id: -1,
            firstName: '',
            login: '',
            secondName: '',
            token: '',
            isAuth: false
        }
        this.clients = []
    }

    @action
    login = (user: UserInterface, token: string | null) => {
        this.user = {
            token: token as string,
            isAuth: true,
            secondName: user.secondName,
            login: user.login,
            firstName: user.firstName,
            id: user.id
        }
    }

    @action
    safeUser = (token: string | null) => {
        //todo switch to cookie
        localStorage.setItem('user',token as string)
    }

    @action
    clearUser = () => {
        localStorage.clear()
        this.user = {} as UserInterface
        this.clients = []
    }

    @action
    clearClients = () => this.clients = []

    @action
    addClient = (client: ClientInterface) => this.clients.push(new ClientMob(client))
}
export default UserMob