import {useEffect, Suspense} from "react";
import {useObserver} from "mobx-react-lite";
import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import {useHttp} from "./useHttp";
import {urls} from "../helpers/urls";
import {useUser} from "./useUser";
import {routes} from "../helpers/routes";
import WaitingPage from "../pages/LoadingPage";
import {UserInterface} from "../interfaces/UserInterface";
const Login = React.lazy(() => import('../pages/Login'))
const HomePage = React.lazy(() => import('../pages/Home'))
const ClientPge = React.lazy(() => import('../pages/ClientPage'))
const AllLaunchesStatisticPage = React.lazy(() => import('../pages/AllLaunchesStatisticPage'))

export const useRoutes = () => {
    const {loading, request} = useHttp()
    const user = useUser()

    useEffect(() => {
        const token = localStorage.getItem('user')
        request(urls.login.url, urls.login.method, null, token)
            .then(data => {
                user.login(data as UserInterface, token)
                user.safeUser(token)
            })
            .catch(() => {
                user.clearUser()
            })
    },[user, request])

    return useObserver(() => (
        <Switch>
            <Suspense fallback={<WaitingPage/>}>
                {loading? <>
                    <Route exact path={routes.waiting}>
                        <WaitingPage/>
                    </Route>
                    <Redirect to={routes.waiting}/>
                </>:<>
                    {user.user.isAuth? <>
                        <Route exact path={routes.toHome}>
                            <HomePage/>
                        </Route>
                        <Route path={routes.toClient}>
                            <ClientPge/>
                        </Route>
                        <Route path={routes.toAllLaunchesStatistic}>
                            <AllLaunchesStatisticPage/>
                        </Route>
                        <Redirect to={routes.toHome}/>
                    </>:<>
                        <Route exact path={routes.toLogin}>
                            <Login/>
                        </Route>
                        <Redirect to={routes.toLogin}/>
                    </>}
                </>}
            </Suspense>
        </Switch>
    ))
}
