import React from 'react';
import {useRoutes} from "./hooks/useRoutes";
import { ThemeProvider } from '@material-ui/core/styles';
import {theme} from "./context/CustomThemeProvider";
import {
  BrowserRouter as Router
} from "react-router-dom"


function App() {
  const routes = useRoutes()

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {routes}
      </Router>
    </ThemeProvider>
  );
}

export default App;
