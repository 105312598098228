import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#81c784',
        },
        secondary: {
            main: '#e57373',
        },
        success:{
            main:'#4791db'
        }
    },
});
